import React from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import qs from 'qs';
import styled from 'styled-components';
import { connect } from 'react-redux';
import MakeRequest from '../../request';
import {
  getMediaBucketList,
  getEditFileSelectApiKey,
  getEditFileSelectApiUrl,
  getFileSelectDataSource,
} from '../../state/config/selectors';
import { SelectElement } from '../../ui-components/inputs';
import Navigation from './navigation';
import Downloader from './downloader';
import { videoExtensionArray, imageExtensionArray } from './treeView';

const Spinner = styled.div`
  position: absolute;
  left: calc(50% - 30px);
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(246, 157, 45, 0.15);
  border-right: 6px solid rgba(246, 157, 45, 0.15);
  border-bottom: 6px solid rgba(246, 157, 45, 0.15);
  border-top: 6px solid rgba(246, 157, 45, 0.8);
  border-radius: 100%;
`;

const VideoContainer = styled.div`
  position: relative;
  right: 0%;
  padding: 10px;
  width: 70%;
`;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
`;

const Column = styled.div`
  flex: 100%;
  padding: 10px;
  max-width: 30%;
  box-sizing: border-box;
`;

const MediaWrapper = styled.div`
  display: table;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: & video {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    background: #262424;
  }
`;

const MediaPlaceholder = styled.div`
  height: 100%;
  background: #dcdcdc;
  border-radius: 0.4rem;
  color: #000;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  font-size: 14pt;
  box-shadow: 0 15px 15px rgba(0,0,0,0.75);
`;

const SpeedButton = styled.div`
      text-align: center;
      width: 55px;
      height: 20px;
      position: absolute;
      left: 0%;
      right: 0%;
      top: 0%;
      bottom: 0%;
      color: white;
      margin-top: 5px;
      margin-left: 5px;
      border: 1px #a2a2a2 solid;
      border-radius: 10px;
      background: #c3c1c1;
      opacity: 0.3;
      color: ${props => (props.playbackStep === 1 ? 'white' : 'red')}
      opacity: ${props => (props.playbackStep === 1 ? 0.3 : 0.5)}
      &:before {
        text-align: center;
        position: absolute;
        top: 10%;
        left: 10px;
        padding-left: 10px;
        content: '${props => props.playbackStep}X';
      }
  `;

class FileSelect extends React.Component {
  state = {
    showVideoSpinner: false,
    playbackStep: 1,
    itemType: null,
    itemMeta: null,
    selectedMediaBucket: '',
  };
  constructor(props) {
    super(props);
    this.downloader = React.createRef();
  }

  componentDidMount = async () => {
    let search = this.props.withDownloader ? window.location.search : this.props.location.search;
    const { mediaBucket: selectedMediaBucket } = await qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    this.setState({ selectedMediaBucket: selectedMediaBucket ? selectedMediaBucket : this.props.mediaBucketList[0] });
  };

  getSignedUrlForVideoAndLoadPreview = async (s3object, itemType, metadata) => {
    if (!itemType) {
      var fileExtension = s3object
        .split('.')
        .pop()
        .toLowerCase();

      console.log({ fileExtension });

      if (videoExtensionArray.includes(fileExtension)) {
        itemType = 'video';
      }

      if (imageExtensionArray.includes(fileExtension)) {
        itemType = 'image';
      }

      if (fileExtension == 'txt') {
        itemType = 'text';
      }
    }

    const { fileSelectApiUrl, fileSelectApiKey } = this.props;
    const { selectedMediaBucket } = this.state;

    let response;

    this.setState({ showVideoSpinner: true });

    let queryParams = [`path=${s3object}`, 'fileType=streamProxy', `s3Bucket=${selectedMediaBucket}`];

    const {
      data: { url },
    } = await MakeRequest({ endpoint: { url: fileSelectApiUrl + '/s3/url', xApiKey: fileSelectApiKey }, queryParams });

    if (itemType == 'text') {
      response = await axios(url, {
        responseType: 'text',
      });
    }

    metadata && this.setState({ itemMeta: metadata });

    this.setState({
      showVideoSpinner: false,
      itemType: itemType ? itemType : 'video',
      mediaSource: response ? response.data : url,
      playbackStep: 1,
    });
  };

  renderType = () => {
    const itemType = this.state.itemType;
    console.log(itemType);

    return (
      <MediaWrapper video={this.state.itemType == 'video' && true}>
        {(() => {
          switch (itemType) {
            case null:
              return <MediaPlaceholder>Your content will be played here</MediaPlaceholder>;

            case 'text':
              return (
                <div style={{ color: 'white', wordBreak: 'break-all', minHeight: '100px' }}>
                  {this.state.mediaSource &&
                    this.state.mediaSource.split('\n').map((i, key) => {
                      return <div key={key}>{i}</div>;
                    })}
                </div>
              );

            case 'image':
              return <img src={this.state.mediaSource} style={{ boxSizing: 'border-box', width: '100%' }} />;

            case 'video':
              return (
                <React.Fragment>
                  <video
                    id="videoPlayer"
                    controls
                    preload="auto"
                    width="100%"
                    controlsList="nodownload"
                    key={`k-${this.state.mediaSource}`}
                    autoPlay={true}
                    style={{ maxHeight: '50vh' }}
                  >
                    {this.state.mediaSource && <source src={this.state.mediaSource} type="video/mp4" />}
                  </video>
                  <SpeedButton
                    playbackStep={this.state.playbackStep}
                    onClick={() => {
                      this.fastRender();
                    }}
                  />
                  <div
                    style={{
                      color: 'white',
                      backgroundColor: '#252829',
                      padding: 10,
                      overflowY: 'scroll',
                      position: 'relative',
                      height: '360px',
                    }}
                  >
                    <div
                      style={{
                        textDecoration: 'underline',
                        fontSize: 15,
                        padding: 13,
                      }}
                    >
                      Technical Information
                    </div>
                    {this.state.itemMeta &&
                      Object.keys(this.state.itemMeta).map(key => {
                        return (
                          <div key={key} style={{ display: 'inline-flex', width: '100%', padding: '2px 5px' }}>
                            <div
                              style={{
                                width: '50%',
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-all',
                                padding: '0px 5px',
                              }}
                            >
                              {key}:
                            </div>
                            <div
                              style={{
                                width: '50%',
                                padding: '0px 5px',
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-all',
                              }}
                            >
                              {this.state.itemMeta[key]}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </React.Fragment>
              );
          }
        })()}
      </MediaWrapper>
    );
  };

  loadAndPlayVideo = mediaSource => {
    this.setState({ showVideoSpinner: false, playbackStep: 1 });
    if (this.state.mediaSource !== mediaSource) {
      this.setState({ mediaSource }, () => {
        let video = document.getElementById('videoPlayer');
        video.play();
      });
    }
  };

  resetlist = () => {
    var lis = document.querySelectorAll('#rightul li');
    for (var i = 0; i < lis.length; i++) {
      var li = lis[i];
      li.parentNode.removeChild(li);
    }
  };

  fastRender() {
    let speed = this.state.playbackStep;

    if (speed < 4) {
      speed++;
      this.setState({ playbackStep: speed });
      document.querySelector('video').playbackRate = speed;
    } else {
      this.setState({ playbackStep: 1 });
      document.querySelector('video').playbackRate = 1;
    }
  }

  download = node =>
    this.props.withDownloader && this.downloader.current ? this.downloader.current.download(node) : null;

  onSelectedMediaBucketChange = e => {
    this.props.history.replace({ search: null });
    this.setState({ selectedMediaBucket: e.target.value });
  };

  render() {
    const { withDownloader, mediaBucketList } = this.props;

    return (
      <React.Fragment>
        {/* <div style={{ padding: '10px', width: '30%', boxSizing: 'border-box' }}>
          <SelectElement onChange={this.onSelectedMediaBucketChange} value={this.state.selectedMediaBucket}>
            {mediaBucketList.map(bucket => (
              <option key={bucket} value={bucket}>
                {bucket}
              </option>
            ))}
          </SelectElement>
        </div> */}
        <Container>
          <Column>
            <div style={{ marginBottom: '10px' }}>
              <SelectElement onChange={this.onSelectedMediaBucketChange} value={this.state.selectedMediaBucket}>
                {mediaBucketList.map(bucket => (
                  <option key={bucket} value={bucket}>
                    {bucket}
                  </option>
                ))}
              </SelectElement>
            </div>
            <Navigation
              key={this.state.selectedMediaBucket}
              mediaBucket={this.state.selectedMediaBucket}
              fileSelectApiKey={this.props.fileSelectApiKey}
              fileSelectApiUrl={this.props.fileSelectApiUrl}
              fileSelectDataSource={this.props.fileSelectDataSource}
              preview={this.getSignedUrlForVideoAndLoadPreview}
              download={withDownloader ? this.download : null}
            ></Navigation>
          </Column>
          <VideoContainer>
            {this.state.showVideoSpinner && <Spinner style={{ zIndex: '100' }} />}
            {this.renderType()}
            {this.props.withDownloader ? (
              <Downloader
                ref={this.downloader}
                style={{ maxHeight: '25vh', overflowY: 'scroll' }}
                mediaBucket={this.state.selectedMediaBucket}
              />
            ) : null}
          </VideoContainer>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(state => ({
    mediaBucketList: getMediaBucketList()(state),
    fileSelectApiUrl: getEditFileSelectApiUrl()(state),
    fileSelectApiKey: getEditFileSelectApiKey()(state),
    fileSelectDataSource: getFileSelectDataSource()(state),
  }))(FileSelect)
);
