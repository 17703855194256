import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { find } from 'lodash/fp';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { PrimaryButtonElement } from '../../ui-components/buttons';
import { Body1 } from '../../ui-components/typography';
import { ProgressBar } from '../../ui-components/progressBar';
import { getStatusApiKey, getStatusApiUrl } from '../../state/config/selectors';
import MakeRequest from '../../request';
import { PrimaryTextColor } from '../../ui-components/colors';
import { CardContainer, CardContainerBody } from '../../ui-components/card';

const Spinner = styled.div`
  height: 14px;
  width: 14px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
`;

const EventType = styled.h4`
  background-color: ${({ eventType }) =>
    eventType == 'initialized'
      ? '#f59f08'
      : eventType == 'download'
      ? 'red'
      : eventType == 'Adobe'
      ? '#000'
      : eventType == 'Davinci Resolve'
      ? '#bd295b'
      : eventType == 'approvals'
      ? 'lightgreen'
      : eventType == 'conform'
      ? '#359036'
      : '#f59f08'};
  border: none;
  color: ${({ eventType }) => (eventType == 'approvals' ? 'black' : eventType == 'Adobe' ? '#ea77ff' : 'white')};
  padding: 4px 10px;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px !important;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 16px;
`;

const StyledFontAwesomeIcon = styled.i`
  margin-right: 10px;
  color: ${PrimaryTextColor};
`;

const StyledEventDetailText = styled.h3`
  font-size: 11px;
  color: ${PrimaryTextColor};
`;

class History extends React.Component {
  state = { events: [], loading: false, lastKey: null };

  componentDidMount = async () => {
    this.fetchData();
  };

  showProgressBar = ({ slug }) => {
    if (slug === 'progress') {
      return true;
    }
  };

  isConformFinished = groupUUID => {
    let event = find({
      groupUUID,
      eventType: 'conform',
      message: 'Conform process completed',
    })(this.state.events);

    if (event) {
      return true;
    }

    return false;
  };

  getLoadingItem = () => (
    <TimelineItem
      key={'loadingItem'}
      dateStyle={{ display: 'none' }}
      bodyContainerStyle={{
        background: '#262525',
        color: 'white',
        padding: '20px',
        borderRadius: '8px',
      }}
      style={{ color: '#ffba2d', fontSize: 24 }}
    >
      <h1 style={{ fontSize: 17 }}>LOADING ...</h1>
    </TimelineItem>
  );

  fetchData = async () => {
    this.setState({ loading: true });

    const { statusApiUrl, statusApiKey } = this.props;

    let editJobId = this.props.match.params.editJobId;

    if (!editJobId) {
      editJobId = localStorage.getItem('editJobId');
    }

    const { data } = await MakeRequest({
      endpoint: { url: statusApiUrl + '/status/' + editJobId, xApiKey: statusApiKey },
    });

    let events = data.status;

    if (events.length > 1) {
      events.sort(function(a, b) {
        let keyA = new Date(a.dateAddedInMicroSeconds);
        let keyB = new Date(b.dateAddedInMicroSeconds);
        if (keyA < keyB) {
          return 1;
        }
        if (keyA > keyB) {
          return -1;
        }
        return 0;
      });
    }

    this.setState({ events: events, lastKey: data.lastKey, loading: false });
  };

  fetchMoreData = async () => {
    let editJobId = this.props.match.params.editJobId;
    if (!editJobId) {
      editJobId = localStorage.getItem('editJobId');
    }

    const { statusApiKey, statusApiUrl } = this.props;
    let { lastKey, events } = this.state;

    const url = `${statusApiUrl}/status/${editJobId}?lastKey=${encodeURIComponent(JSON.stringify(lastKey))}`;

    let { data } = await MakeRequest({
      endpoint: { url: url, xApiKey: statusApiKey },
    });

    let newEvents = events.concat(data.status);

    if (newEvents.length > 1) {
      newEvents.sort(function(a, b) {
        let keyA = new Date(a.dateAddedInMicroSeconds);
        let keyB = new Date(b.dateAddedInMicroSeconds);
        if (keyA < keyB) {
          return 1;
        }
        if (keyA > keyB) {
          return -1;
        }
        return 0;
      });
    }

    this.setState({ events: newEvents, lastKey: data.lastKey });
  };

  render = () => {
    const { events, loading } = this.state;

    return (
      <React.Fragment>
        <CardContainer>
          <CardContainerBody>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
              <PrimaryButtonElement style={{ width: 'auto' }} onClick={this.fetchData}>
                Refresh
              </PrimaryButtonElement>
            </div>
            {events.length === 0 && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Body1>No events logged!</Body1>
              </div>
            )}
            {events.length > 0 && (
              <Timeline lineColor={'#f69c2d'}>
                <InfiniteScroll
                  dataLength={events.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.lastKey !== null}
                  loader={this.getLoadingItem()}
                  style={{ overflow: 'none' }}
                >
                  {events.map(event => {
                    var eventTimestamp = new Date(0); // The 0 there is the key, which sets the date to the epoch
                    eventTimestamp.setUTCSeconds(event.dateAddedInSeconds);

                    if (!event.conformPlatform || event.conformPlatform == 'adobeMediaEncoder') {
                      event.platform = 'Adobe';
                    } else {
                      if (event.conformPlatform == 'davinciResolveBackend') {
                        event.platform = 'Davinci Resolve';
                      }
                    }
                    return (
                      <TimelineItem
                        key={event.dateAddedInMicroSeconds}
                        dateText={eventTimestamp.toLocaleString('en-US', { timeZone: 'Europe/London' })}
                        bodyContainerStyle={{
                          background: '#dfdfdf',
                          padding: '10px 10px 10px 10px',
                          color: '#000',
                          fontSize: 11,
                          fontWeight: 'normal',
                          marginLeft: 26,
                          border: '1px solid #b4b6b6',
                          borderRadius: '8px',
                        }}
                        dateInnerStyle={{
                          background: '#dfdfdf',
                          color: '#000',
                          fontSize: 11,
                          textIndent: 20,
                          // border: '1px solid #b4b6b6',
                        }}
                        dateStyle={{
                          background: 'b4b6b6',
                          top: 0,
                        }}
                        style={{ color: PrimaryTextColor }}
                      >
                        {event.eventType == 'approvals' && (
                          <EventType eventType={event.platform}>{event.platform}</EventType>
                        )}

                        {event.eventType == 'submission' && (
                          <EventType eventType={event.platform}>{event.platform}</EventType>
                        )}

                        <EventType eventType={event.eventType}>{event.eventType}</EventType>

                        <StyledEventDetailText style={{ paddingLeft: 10, paddingTop: 10 }}>
                          <StyledFontAwesomeIcon className="fa fa-info-circle"></StyledFontAwesomeIcon> {event.message}
                        </StyledEventDetailText>

                        <StyledEventDetailText style={{ paddingLeft: 10, paddingTop: 5 }}>
                          <StyledFontAwesomeIcon className="fa fa-user-circle"></StyledFontAwesomeIcon>
                          {event.userName}
                        </StyledEventDetailText>

                        {event.eventType == 'approvals' && (
                          <StyledEventDetailText style={{ paddingLeft: 10, paddingTop: 5 }}>
                            <StyledFontAwesomeIcon className="fa fa-play-circle"></StyledFontAwesomeIcon> Version:{' '}
                            {event.groupUUID}
                          </StyledEventDetailText>
                        )}

                        {event.eventType == 'submission' && (
                          <StyledEventDetailText style={{ paddingLeft: 10, paddingTop: 5 }}>
                            <StyledFontAwesomeIcon className="fa fa-play-circle"></StyledFontAwesomeIcon> Version:{' '}
                            {event.groupUUID}
                          </StyledEventDetailText>
                        )}

                        {event.eventType == 'approvals' && this.showProgressBar(event) && (
                          <div style={{ marginTop: 5 }}>
                            <ProgressBar percentage={event.progress} text={event.statusDetails} />
                          </div>
                        )}

                        {event.eventType == 'submission' && this.showProgressBar(event) && (
                          <div style={{ marginTop: 5 }}>
                            <ProgressBar percentage={event.progress} text={event.statusDetails} />
                          </div>
                        )}
                      </TimelineItem>
                    );
                  })}
                </InfiniteScroll>
              </Timeline>
            )}
          </CardContainerBody>
        </CardContainer>
      </React.Fragment>
    );
  };
}

export default connect(state => ({
  statusApiUrl: getStatusApiUrl()(state),
  statusApiKey: getStatusApiKey()(state),
}))(History);
